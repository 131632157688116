function displaySearchBar(path) {
  const pathList = path.split("/");

  return (
    (isNaN(pathList[pathList.length - 1]) &&
      !path.includes("inicio") &&
      !path.includes("agenda") &&
      !path.includes("pdf-preview") &&
      !path.includes("avisos") &&
      pathList[pathList.length - 1] !== "novo") ||
    path.includes("clientes") ||
    path.includes("fornecedores")
  );
}

const homeUtils = {
  displaySearchBar,
};

export default homeUtils;
