import api from "./api";

export async function postNotification(body, token) {
  const response = await api.post("/notification", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getNotificationData(notificationId, token) {
  const response = await api.get(`/notification/${notificationId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getNotifications({ skip, take, limit, read }, token) {
  const response = await api.get(
    `/notification/many?skip=${skip}&take=${take}&limit=${limit}&read=${read}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function deleteNotification(ids, token) {
  const response = await api.delete(`/notification?ids=${ids}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteAllNotifications(token) {
  const response = await api.delete("/notification/all", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
