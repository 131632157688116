import { FaRegCalendar, FaRegEye } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Select from "../../../components/forms/notification-form/Select";
import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import { Container, ParamsWrapper } from "./styles";
import notificationUtils from "../../../utils/notification-utils";
import useNotifications from "../../../hooks/api/notification/useNotifications";
import toastUtils from "../../../utils/toast-utils";
import ListWrapper from "../../../components/ListWrapper";
import { ListItem } from "./styles";

export default function Notifications() {
  const responseLength = 20;
  const { getNotifications, notificationsLoading } = useNotifications();
  const [displayParams, setDisplayParams] = useState(
    notificationUtils.notificationEmptyDisplayParams
  );
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    reloadNotifications();
  }, [displayParams.period, displayParams.status]);

  async function loadNotifications() {
    const params = {
      skip: notifications.length,
      take: responseLength,
      limit: displayParams.period.value || new Date().toISOString(),
      read: displayParams.status.value,
    };

    try {
      const response = await getNotifications(params);

      if (response) setNotifications(notifications.concat(response));
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function reloadNotifications() {
    const params = {
      skip: 0,
      take: responseLength,
      limit: displayParams.period.value || new Date().toISOString(),
      read: displayParams.status.value,
    };

    try {
      const response = await getNotifications(params);

      if (response) setNotifications(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  return (
    <Section title="Avisos" theme={theme}>
      <Container theme={theme}>
        <ParamsWrapper displayFilters={true}>
          <Select
            id="period"
            icon={FaRegCalendar}
            placeholder={"Período..."}
            enabled={true}
            list={notificationUtils.periodOptions}
            selected={displayParams.period}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="status"
            icon={FaRegEye}
            placeholder={"Status..."}
            enabled={true}
            list={notificationUtils.statusOptions}
            selected={displayParams.status}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        {notifications.length === 0 ? (
          <h4>Você não tem nenhum aviso </h4>
        ) : (
          <ListWrapper
            offset={responseLength}
            list={notifications}
            loadList={loadNotifications}
            loading={notificationsLoading}
          >
            {notifications.map((notification, index) => (
              <NotificationWrapper
                key={index}
                id={notification.notificationId}
                message={notification.message}
                read={notification.read}
                createdAt={notificationUtils.formatNotificationDate(
                  notification.createdAt
                )}
              />
            ))}
          </ListWrapper>
        )}
      </Container>
    </Section>
  );
}

function NotificationWrapper({ id, message, read, createdAt }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} read={read} onClick={() => navigate(`${id}`)}>
      <div>
        <p>{message}</p>
        <h6>{createdAt}</h6>
        <GoDotFill />
      </div>
    </ListItem>
  );
}
