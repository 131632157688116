import useAsync from "../../useAsync";

import * as notificationApi from "../../../services/notificationApi";
import useToken from "../../useToken";

export default function useNotifications() {
  const token = useToken();

  const {
    data: notifications,
    loading: notificationsLoading,
    error: notificationsError,
    act: getNotifications,
  } = useAsync((data) => notificationApi.getNotifications(data, token), false);

  return {
    notifications,
    notificationsLoading,
    notificationsError,
    getNotifications,
  };
}
