import useAsync from "../../useAsync";

import * as notificationApi from "../../../services/notificationApi";
import useToken from "../../useToken";

export default function useNotification() {
  const token = useToken();

  const {
    data: notificationData,
    loading: notificationDataLoading,
    error: notificationDataError,
    act: getNotificationData,
  } = useAsync(
    (data) => notificationApi.getNotificationData(data, token),
    false
  );

  return {
    notificationData,
    notificationDataLoading,
    notificationDataError,
    getNotificationData,
  };
}
