import dayjs from "dayjs";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

const periodOptions = [
  { label: "Todos", value: dayjs().toISOString() },
  {
    label: "Há 1 dia",
    value: dayjs().subtract(1, "day").endOf("day").toISOString(),
  },
  {
    label: "Há 1 semana",
    value: dayjs().subtract(1, "week").endOf("day").toISOString(),
  },
  {
    label: "Há 1 mês",
    value: dayjs().subtract(1, "month").endOf("day").toISOString(),
  },
  {
    label: "Há 1 ano",
    value: dayjs().subtract(1, "year").endOf("day").toISOString(),
  },
];

const statusOptions = [
  { label: "Todos", value: "" },
  { label: "Lido", value: true },
  { label: "Não lido", value: false },
];

const notificationEmptyDisplayParams = {
  period: { label: "", value: null },
  status: { label: "", value: "" },
};

const emptyNotification = {
  notificationId: 0,
  userId: 0,
  message: "",
  read: false,
  createdAt: "",
};

function formatNotificationDate(date) {
  if (dayjs(date).isSame(dayjs(), "day"))
    return `Hoje - ${dayjs(date).format("HH:mm")}`;
  else if (dayjs(date).isSame(dayjs().subtract(1, "day"), "day"))
    return `Ontem - ${dayjs(date).format("HH:mm")}`;
  else
    return `${dayjs(date).format("ddd, DD")} de ${dayjs(date).format(
      "MMM"
    )} de ${dayjs(date).format("YYYY - HH:mm")}`;
}

const notificationUtils = {
  periodOptions,
  notificationEmptyDisplayParams,
  statusOptions,
  emptyNotification,
  formatNotificationDate,
};

export default notificationUtils;
