import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 4px;
  padding: 0 5px;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: ${({ theme, selected }) =>
      selected ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }

  svg:hover {
    color: ${({ theme }) => theme.COLORS.BLUE};
  }
`;

export const Counter = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -2px;
  background-color: ${({ theme }) => theme.COLORS.BLUE};
  color: ${({ theme }) => theme.COLORS.WHITE};
  border: solid 2px ${({ theme }) => theme.COLORS.WHITE};
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  font-weight: 700;
`;
