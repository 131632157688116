import { useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { FaCirclePlus } from "react-icons/fa6";

import theme from "../../../../styles/theme";
import { Container, Row } from "./styles";
import ChecklistSelect from "../../../../components/forms/report-form/ChecklistSelect";
import AddPictureButton from "../AddPictureButton";
import ChecklistComment from "../ChecklistComment";
import AddPicture from "../AddPicture";

export default function ChecklistSection({
  section,
  options,
  answers,
  setAnswers,
  enabled,
  newAnswer,
  checkDemands,
  extraItems,
  setExtraItems,
}) {
  let counter = 1;

  function addExtraItem() {
    const newItem = {
      code: "COMP",
      description: "ITEM COMPLEMENTAR (detalhar)",
      id: extraItems.length + 1,
      obs: "",
      reference: "",
      sectionId: section.id,
    };

    const newAnswer = {
      answerId: 0,
      code: "COMP",
      section: { id: section.id, name: section.description },
      status: { label: "", value: 0 },
      obs: "",
      pictures: [],
    };

    setExtraItems([...extraItems, newItem]);

    setAnswers({ ...answers, [newItem.id]: newAnswer });
  }

  return (
    <Container theme={theme}>
      <Row className="title" theme={theme}>
        <span className="index"></span>
        <span className="description">{section.name}</span>
        <span className="reference desktop">REFERÊNCIA</span>
        <span className="reference mobile">REF.</span>
        <span className="status desktop">SITUAÇÃO</span>
        <span className="status mobile">SIT.</span>
      </Row>

      {section.items
        .concat(extraItems.filter(({ sectionId }) => sectionId === section.id))
        .map((item, index) => {
          const isSubitem =
            item.description[1] === ")" || item.description[0] === "-";

          const hasSubitem =
            section.items[index + 1]?.code[
              section.items[index + 1]?.code.length - 1
            ] === "1" && !isSubitem;

          return (
            <ChecklistItem
              key={index}
              item={item}
              index={index}
              counter={isSubitem ? counter : counter++}
              options={options}
              answers={answers}
              setAnswers={setAnswers}
              enabled={enabled}
              isSubitem={isSubitem}
              hasSubitem={hasSubitem}
              newAnswer={newAnswer}
              sectionId={section.id}
              checkDemands={checkDemands}
            />
          );
        })}

      {enabled && (
        <Row theme={theme}>
          <span className="add-item" onClick={addExtraItem}>
            <FaCirclePlus />
            <p>Adicionar item</p>
          </span>
        </Row>
      )}
    </Container>
  );
}

function ChecklistItem({
  item,
  index,
  counter,
  options,
  answers,
  setAnswers,
  enabled,
  isSubitem,
  hasSubitem,
  newAnswer,
  sectionId,
  checkDemands,
}) {
  const [selected, setSelected] = useState(0);
  const [displayComment, setDisplayComment] = useState(
    item.code === "COMP" ? true : false
  );
  const [addingPhoto, setAddingPhoto] = useState(false);
  const hasPictures = answers[item.id]?.pictures.length > 0;

  useEffect(() => {
    setSelected(answers[item.id]?.status) || { label: "", value: 0 };

    if (!answers[item.id]?.status.value && item.code !== "COMP")
      setDisplayComment(false);
  }, [answers[item.id]?.status.value]);

  function handleComment(value) {
    setAnswers({
      ...answers,
      [item.id]: { ...answers[item.id], obs: value },
    });
  }

  function handleSelect({ name, value, form, setForm, sectionId }) {
    setSelected(value);
    const answer = { ...form, [name]: { ...form[name], status: value } };
    setForm(answer);

    value.value === 1 && item.code !== "COMP"
      ? setDisplayComment(false)
      : setDisplayComment(true);

    checkDemands(sectionId, answer);
  }

  return (
    <>
      <Row key={index} theme={theme} enabled={enabled} isSubitem={isSubitem}>
        <span className="index">{!isSubitem && `${counter}.`}</span>
        <span className="description">
          <span>
            <p>{item.description}</p>
            {answers[item.id]?.status.value !== 0 &&
              answers[item.id]?.status.value !== 1 &&
              !hasSubitem && (
                <>
                  {displayComment ? (
                    <AiOutlineCaretUp
                      onClick={() => setDisplayComment(false)}
                    />
                  ) : (
                    <AiOutlineCaretDown
                      onClick={() => setDisplayComment(true)}
                    />
                  )}
                </>
              )}
          </span>

          {(enabled || (!newAnswer && hasPictures)) && !hasSubitem && (
            <AddPictureButton
              setAddingPhoto={setAddingPhoto}
              isEmpty={!hasPictures}
            />
          )}

          {displayComment && (
            <ChecklistComment
              answers={answers}
              handleComment={handleComment}
              item={item}
              enabled={enabled}
              required={item.code === "COMP"}
            />
          )}
        </span>
        <span className="reference">{!hasSubitem && item.reference}</span>
        <span className="status">
          {!hasSubitem && (
            <ChecklistSelect
              id={item.id}
              placeholder={enabled ? "Selecione..." : ""}
              enabled={enabled}
              list={options}
              selected={selected}
              required
              form={answers}
              setForm={setAnswers}
              handleForm={handleSelect}
              sectionId={sectionId}
            />
          )}
        </span>
      </Row>

      {addingPhoto && (
        <AddPicture
          answers={answers}
          setAnswers={setAnswers}
          addingPhoto={addingPhoto}
          setAddingPhoto={setAddingPhoto}
          id={item.id}
          enabled={enabled}
        />
      )}
    </>
  );
}
