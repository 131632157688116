import useAsync from "../../useAsync";

import * as notificationApi from "../../../services/notificationApi";
import useToken from "../../useToken";

export default function useSaveNotification() {
  const token = useToken();

  const {
    loading: saveNotificationLoading,
    error: saveNotificationError,
    act: saveNotification,
  } = useAsync((data) => notificationApi.postNotification(data, token), false);

  return {
    saveNotificationLoading,
    saveNotificationError,
    saveNotification,
  };
}
