import { FaBell, FaRegBell } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

import { Container, Counter } from "./styles";
import theme from "../../styles/theme";

export default function NotificationBell({ count }) {
  const pathName = useLocation().pathname;
  const selected = pathName.includes("avisos");
  const navigate = useNavigate();

  return (
    <Container
      theme={theme}
      selected={selected}
      onClick={() => navigate("/painel/avisos")}
    >
      {selected ? <FaBell /> : <FaRegBell />}

      {count > 0 && <Counter theme={theme}>{count}</Counter>}
    </Container>
  );
}
